import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// ScrollToTop component that scrolls to the top of the page on route change
const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when route changes
  }, [location]);

  return null;
};

export default ScrollToTop;
