import { useContext } from "react";

import Table from '../../components/Table';

import Config from '../../config/Config';
import '../../assets/css/admin.css';
import { CountryContext } from "../../context/Country";

function ManageRequests() {
  const { country } = useContext(CountryContext);

  const get_posted_posts_url = `${Config.backendURL}${Config.adminPostsEndpoint}?country=${country}&current_status=${Config.Status.POST.POSTED}`;
  const get_new_posts_url = `${Config.backendURL}${Config.adminPostsEndpoint}?country=${country}&current_status=${Config.Status.POST.NEW}`;
  const get_finished_posts_url = `${Config.backendURL}${Config.adminPostsEndpoint}?country=${country}&current_status=${Config.Status.POST.FULFILLED}&current_status=${Config.Status.POST.SUCCEEDED}&current_status=${Config.Status.POST.FAILED}`;

  const get_students_info_url = `${Config.backendURL}${Config.adminUsersEndpoint}/all?is_student=${true}`;

  const table_headers = {
    common_post_headers: ['post_id', 'course_name', 'academic_level', 'grade_level', 'major', 'current_status', 'country', 'location', 'number_of_sessions', 'frequency_per_week', 'online_sessions', 'additional_info', 'price', 'price_negotiable', 'start_date', 'client_email', 'instagram_account', 'tutor_email'],
    common_students_headers: ['email', 'instagram_account', 'phone_number', 'given_name', 'family_name', 'gender', 'birthdate'],
    posted_post_headers: ['tutor_applications_count', 'tutor_applications_accepted_count']
  }

  return (
    <div className='admin'>
      <h1>Students Requests</h1>
      <Table
        url={get_posted_posts_url}
        tableHeaders= {[...table_headers.posted_post_headers, ...table_headers.common_post_headers]}
        tableName="POSTED"
        isPostTable={true}
      />
      <Table
        url={get_new_posts_url}
        tableHeaders={table_headers.common_post_headers}
        tableName="NEW"
        isPostTable={true}
      />
      <Table
        url={get_finished_posts_url}
        tableHeaders={table_headers.common_post_headers}
        tableName="FINISHED"
        isPostTable={true}
      />
      <Table
        url={get_students_info_url}
        tableHeaders={table_headers.common_students_headers}
        tableName="STUDENTS-INFO"
        isStudentTable={true}
      />
    </div>
  )
}

export default ManageRequests;
