import { useState, useEffect, useContext } from "react";
import { useMediaQuery } from "react-responsive";


import Chart from '../../components/BarChart';
import CardsComponent from '../../components/Cards';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Config from '../../config/Config';
import '../../assets/css/admin.css';
import { CountryContext } from "../../context/Country";
import Swal from 'sweetalert2';
import purple_background from "../../assets/images/admin/card-1.png";
import green_background from "../../assets/images/admin/card-2.png";
import home_background from '../../assets/images/landing-images/get-started-bg.png';


function Admin() {
  const [postsData, setPostsData] = useState([]);
  const [tutorApplicationsData, setTutorApplicationsData] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const { country } = useContext(CountryContext);

  const get_posts_url = `${Config.backendURL}${Config.adminPostsEndpoint}?country=${country}`;
  const get_tutor_applications_url = `${Config.backendURL}${Config.adminTutorApplicationsEndpoint}`;

  const count_posts_by_status = (postsData, status) => {
    const filteredPosts = postsData.filter(post => post.current_status === status);
    return filteredPosts.length;
  }

  const count_tutor_applications = (status) => {
    if (status === "") {
      return tutorApplicationsData.length;
    }
    const filteredApplications = tutorApplicationsData.filter(application => application.current_status === status);
    return filteredApplications.length;
  }

  const activePostsCount = count_posts_by_status(postsData, Config.Status.POST.NEW) + count_posts_by_status(postsData, Config.Status.POST.POSTED);
  const fulfilledPostsCount = count_posts_by_status(postsData, Config.Status.POST.FULFILLED);
  const succeededPostsCount = count_posts_by_status(postsData, Config.Status.POST.SUCCEEDED);
  const failedPostsCount = count_posts_by_status(postsData, Config.Status.POST.FAILED);
  const tutorApplicationsCount = count_tutor_applications(Config.Status.TUTOR_APPLICATION.ALL);
  const acceptedTutorApplicationsCount = count_tutor_applications(Config.Status.TUTOR_APPLICATION.ACCEPTED);
  const rejectedTutorApplicationsCount = count_tutor_applications(Config.Status.TUTOR_APPLICATION.REJECTED);


  const ongoing_posts_data = [
    { name: "Active Posts", value: activePostsCount },
    { name: "Fulfilled Posts", value: fulfilledPostsCount }
  ]

  const posts_data = [
    { name: "Succeeded Posts", value: succeededPostsCount },
    { name: "Failed Posts", value: failedPostsCount }
  ]

  const tutor_applications_data = [
    { name: "Tutor Appl.", value: tutorApplicationsCount },
    { name: "Accepted", value: acceptedTutorApplicationsCount },
    { name: "Rejected", value: rejectedTutorApplicationsCount },

  ]

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const get_posts = async () => {
      try {
        const response = await axiosPrivate.get(get_posts_url, {
          signal: controller.signal
        });
        setPostsData(response.data);
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error);
        }
        Swal.fire({
          title: 'Error!',
          text: 'Error getting posts, please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
    const get_tutor_applications = async () => {
      try {
        const response = await axiosPrivate.get(get_tutor_applications_url, {
          signal: controller.signal
        });
        setTutorApplicationsData(response.data);
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error);
        }
        Swal.fire({
          title: 'Error!',
          text: 'Error getting additional tutor applications, please try again later.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
    get_posts();
    get_tutor_applications();

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, []);


  const cards = [
    [{
      photo: purple_background,
      label: "Student Requests",
      description: "Easily manage and process student requests—verify, approve, or decline applications.",
      navigate_to: "admin/requests",
      button_text: "Manage Requests"
    },
    {
      photo: green_background,
      label: "Tutor Applications",
      description: "Review and evaluate tutor CVs—decide whether to accept or reject tutor applications.",
      navigate_to: "admin/tutor/applications",
      button_text: "Review Applications"
    }]
  ];
  const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });



  return (
    <div className='admin'>
      <h1>Admin Control Center</h1>
      {isDesktop && (<div className='charts'>
        <Chart data={ongoing_posts_data} />
        <Chart data={tutor_applications_data} />
        <Chart data={posts_data} />
      </div>)}
      <CardsComponent
        title="Let's get going!"
        description="Manage students requests and tutor applications here :)"
        cards={cards}
        bg_img={home_background}
        bg_style="transparent-bg-color"
        text_style="black-color"
      />
    </div>
  )
}

export default Admin;
