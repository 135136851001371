import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import '../assets/css/navbar.css';
import Config from '../config/Config';
import { CountryContext } from "../context/Country";

function Navbar() {
    const { currentSession, logout, getSession } = useAuth();

    let navigate = useNavigate();
    const location = useLocation();

    const [isStudentMode, setIsStudentMode] = useState(true);
    const { country, setCountry } = useContext(CountryContext);

    useEffect(() => {
        const checkSession = async () => {
            try {
                await getSession();
            } catch (error) {
                if (process.env.NODE_ENV !== 'production') {
                    console.warn(error);
                }
            }
        };
        // Define the paths that should trigger a rerender
        const pathsToRerender = ["/students/home", "/tutors/home", "/"];
        // Only run `checkSession` if the current path matches one of the specified paths
        if (pathsToRerender.includes(location.pathname)) {
            checkSession();
        }
    }, [location.pathname]);

    const handleLogoutClick = () => {
        logout();
        navigate('/');
    }

    const getBackgroundColor = () => {
        switch (location.pathname) {
            case "/students/home":
            case "/students/find/tutor":
            case "/students/select/tutor":
                return Config.purple_color;
            default:
                return Config.green_color;
        }
    };

    useEffect(() => {
        // This allows the Mode value to be changed in the Navbar, when the user presses the back button from the browser
        if (location.pathname.includes("/students")) {
            setIsStudentMode(true);
        } else if (location.pathname.includes("/tutors")) {
            setIsStudentMode(false);
        }
    }, [location.pathname]);

    const handleToggleMode = (event) => {
        const selectedMode = event.target.value;
        if (selectedMode === "student") {
            navigate("/students/home");
        } else if (selectedMode === "tutor") {
            navigate("/tutors/home");
        }
    };
    const handleBackClick = () => {
        window.history.back(); // This navigates back to the previous page
    };
    const handleCountryChange = (event) => {
        setCountry(event.target.value); // Update the country in context
    };

    const isLandingPage = location.pathname === "/";
    const userSetupComplete = localStorage.getItem('userSetupComplete');
    const backgroundColor = getBackgroundColor();

    return (
        <header style={{ background: backgroundColor, position: isLandingPage ? "fixed" : "top" }}>
            <nav className="navbar">
                {currentSession && userSetupComplete && (
                    <span className="navbar-link back-button" onClick={handleBackClick}>
                        <i className="fas fa-solid fa-angle-left fa-2x"></i>
                    </span>
                )}
                <h2 className="logo"><a className="navbar-link" href="/">Find Your Tutor</a></h2>
                <input type="checkbox" id="menu-toggler" />
                <label htmlFor="menu-toggler" id="hamburger-btn">
                    <i className="fas fa-solid fa-bars fa-xs"></i>
                </label>
                <ul className="all-links" style={{ background: backgroundColor }}>
                    {!currentSession && userSetupComplete === null && (
                        <>
                            <li>{<a className="navbar-link" href="/#steps">How it Works</a>}</li>
                            <li>{<a className="navbar-link" href="/#about">About Us</a>}</li>
                            <li>{<a className="navbar-link" href="/#get-started">Get Started</a>}</li>
                            <li>{<a className="navbar-link" href="/#testimonials">Testimonials</a>}</li>
                            <li>{<a className="navbar-link" href="/#contact">Contact</a>}</li>
                            <li>{<a className="navbar-link" href="/login">Login</a>}</li>
                            <li>{<a className="navbar-link" href="/register">Register</a>}</li>
                        </>
                    )
                    }
                    {currentSession && userSetupComplete && (
                        <>
                            <li>
                                <select
                                    value={isStudentMode ? "student" : "tutor"}
                                    onChange={handleToggleMode}
                                    className="navbar-link"
                                    style={{ background: backgroundColor }}
                                >
                                    {isStudentMode ? (
                                        <>
                                            <option value="tutor">Go To Tutor Mode</option>
                                            <option value="student">Student Mode</option>
                                        </>
                                    ) : (
                                        <>
                                            <option value="student">Go To Student Mode</option>
                                            <option value="tutor">Tutor Mode</option>
                                        </>
                                    )}
                                </select>
                            </li>
                            <li>{<a className="navbar-link" onClick={handleLogoutClick}>Logout</a>}</li>

                        </>
                    )
                    }
                    <li>
                        <select
                            value={country}
                            onChange={handleCountryChange}
                            className="navbar-link"
                            style={{ background: backgroundColor }}
                        >
                            <option value="LEB">Lebanon</option>
                        </select>
                    </li>

                </ul>
            </nav>
        </header>
    );
}

export default Navbar;
