import React, { useState, useRef, useContext } from 'react'
import { Col, Container, Form, Row } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Config from '../../config/Config';
import PopupModal from "../../components/PopupModal";
import { useNavigate } from "react-router-dom";
import side_form from "../../assets/images/side-form-1.png";
import { CountryContext } from '../../context/Country';


function FindTutorForm({ setShowModal }) {
  const { country } = useContext(CountryContext);
  const today = new Date().toISOString().split('T')[0];

  const [interest, setInterest] = useState('');
  const [academic_level, setAcademicLevel] = useState('');
  const [major, setMajor] = useState('');
  const [grade_level, setGradeLevel] = useState('');
  const [isMultipleSessions, setisMultipleSessions] = useState(false);
  const [isSchoolCourse, setIsSchoolCourse] = useState(false);
  const [isUniversityCourse, setIsUniversityCourse] = useState(false);
  const [numberSessions, setNumberSessions] = useState(1);
  const [frequencyPerWeek, setFrequencyPerWeek] = useState(0);
  const [course, setCourse] = useState('');
  const [price, setPrice] = useState('');
  const [location, setLocation] = useState('');
  const [online, setOnline] = useState('');
  const [priceNegotiable, setPriceNegotiable] = useState('');
  const [isOnlineSessions, setisOnlineSessions] = useState(false);
  const [isPriceNegotiable, setIsPriceNegotiable] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [importantNotes, setImportantNotes] = useState('');
  const axiosPrivate = useAxiosPrivate();

  const [submitted, setSubmitted] = useState(false);
  const [validated, setValidated] = useState(false);

  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrMsg('');
    setSubmitted(true);
    setLoading(true);

    if (!interest || !course || !price || !location || !online || !startDate) {
      setErrMsg("Please fill in all required fields");
      errRef.current.focus();
    } else {
      const create_post_url = `${Config.backendURL}${Config.postsEndpoint}`;

      const form_data = {
        "course_name": course,
        "academic_level": academic_level,
        "major": major,
        "grade_level": grade_level,
        "price": price,
        "price_negotiable": isPriceNegotiable,
        "number_of_sessions": numberSessions,
        "frequency_per_week": frequencyPerWeek,
        "start_date": startDate,
        "location": location,
        "country": country,
        "additional_info": importantNotes,
        "online_sessions": isOnlineSessions
      }

      try {
        const response = axiosPrivate.post(create_post_url, form_data);
        setShowModal(true);
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(error)
        }
        setErrMsg("Error submitting post, please try again later.");
        errRef.current.focus();
      } finally {
        setLoading(false);
      }
    }
  }

  const handleChangeInterest = (event) => {
    setInterest(event.target.value);
    if (event.target.value === "multiple_sessions") {
      setisMultipleSessions(true);
    } else {
      setisMultipleSessions(false);
    }
  }

  const handleChangeAcademicLevel = (event) => {
    setAcademicLevel(event.target.value);
    if (event.target.value === "School") {
      setIsSchoolCourse(true);
      setIsUniversityCourse(false);
    } else {
      setIsSchoolCourse(false);
      setIsUniversityCourse(true);
    }
  }

  const handleChangeOnline = (event) => {
    setOnline(event.target.value);
    if (event.target.value === "Yes") {
      setisOnlineSessions(true);
    } else {
      setisOnlineSessions(false);
    }
  }

  const handleChangePriceNegotiable = (event) => {
    setPriceNegotiable(event.target.value);
    if (event.target.value === "Yes") {
      setIsPriceNegotiable(true);
    } else {
      setIsPriceNegotiable(false);
    }
  }

  const interest_options = [
    { value: 'multiple_sessions', label: 'Multiple Sessions' },
    { value: 'one_session', label: 'One Session / Project' },

  ];

  const academic_level_options = [
    { value: 'School', label: 'School' },
    { value: 'University', label: 'University' },
  ];

  const grade_level_options = [
    { value: '1', label: '1' }, { value: '2', label: '2' }, { value: '3', label: '3' }, { value: '4', label: '4' }, { value: '5', label: '5' }, { value: '6', label: '6' },
    { value: '7', label: '7' }, { value: '8', label: '8' }, { value: '9', label: '9' }, { value: '10', label: '10' }, { value: '11', label: '11' }, { value: '12', label: '12' },
  ];


  const yes_no_options = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
  ];

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <p ref={errRef} className={errMsg ? "ezy__form-errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="dark" />
          <p>Loading...</p>
        </div>
      ) :
        <Row>
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="findtutor-academic-level" className="mb-2">Academic Level*</Form.Label>
              <Form.Select
                id="findtutor-academic-level"
                value={academic_level}
                onChange={handleChangeAcademicLevel}
                isInvalid={submitted && !academic_level}
              >
                <option value="">Please select</option>
                {academic_level_options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          {isSchoolCourse === true &&
            <Col lg={6}>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="findtutor-grade-level" className="mb-2">Grade*</Form.Label>
                <Form.Select
                  id="findtutor-grade-level"
                  value={grade_level}
                  onChange={(event) => setGradeLevel(event.target.value)}
                  isInvalid={submitted && !grade_level}
                >
                  <option value="">Please select</option>
                  {grade_level_options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          }
          {isUniversityCourse === true &&
            <Col lg={6}>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="findtutor-major">Major</Form.Label>
                <Form.Control
                  id="findtutor-major"
                  type="text"
                  value={major}
                  placeholder="Major Name"
                  onChange={(event) => setMajor(event.target.value)}
                  isInvalid={submitted && !major}
                  maxLength={100}
                />
              </Form.Group>
            </Col>
          }
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="findtutor-course">Course(s)*</Form.Label>
              <Form.Control
                id="findtutor-course"
                type="text"
                value={course}
                placeholder="Course(s) Name(s)"
                onChange={(event) => setCourse(event.target.value)}
                isInvalid={submitted && !course}
                maxLength={100}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="findtutor-interestedIn" className="mb-2">Interested In*</Form.Label>
              <Form.Select
                id="findtutor-interestedIn"
                value={interest}
                onChange={handleChangeInterest}
                isInvalid={submitted && !interest}
              >
                <option value="">Please select</option>
                {interest_options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          {
            isMultipleSessions === true && (
              <>
                <Col lg={6}>
                  <Form.Group className="mb-4">
                    <Form.Label htmlFor="findtutor-nbrSessions">Total Number of Sessions*</Form.Label>
                    <Form.Control
                      type="number"
                      id="findtutor-nbrSessions"
                      value={numberSessions}
                      placeholder="1"
                      onChange={(event) => {
                        const value = Math.max(1, parseInt(event.target.value, 10) || 0); // Ensure positive numbers only
                        setNumberSessions(value);
                      }}
                      min={1}
                      isInvalid={submitted && !numberSessions}
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-4">
                    <Form.Label htmlFor="findtutor-frequency">Frequency per Week*</Form.Label>
                    <Form.Control
                      type="number"
                      id="findtutor-frequency"
                      value={frequencyPerWeek}
                      placeholder="1"
                      onChange={(event) => {
                        const maxFrequency = Math.min(7, numberSessions || 7); // Maximum value
                        const value = Math.min(maxFrequency, Math.max(1, event.target.value)); // Enforce range
                        setFrequencyPerWeek(value);
                      }}
                      min={1}
                      max={Math.min(7, numberSessions || 7)}
                      isInvalid={submitted && !frequencyPerWeek}
                    />
                  </Form.Group>
                </Col>
              </>
            )
          }
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="findtutor-price">Price Willing To Pay ($/session)*</Form.Label>
              <Form.Control
                id="findtutor-price"
                type="number"
                value={price}
                placeholder="$"
                onChange={(event) => {
                  const value = Math.max(1, parseInt(event.target.value, 10) || 0); // Ensure positive numbers only
                  setPrice(value);
                }}
                min={1}
                isInvalid={submitted && !price}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="findtutor-price-negotiable" className="mb-2">Price Negotiable*</Form.Label>
              <Form.Select
                id="findtutor-price-negotiable"
                value={priceNegotiable}
                onChange={handleChangePriceNegotiable}
                isInvalid={submitted && !priceNegotiable}
              >
                <option value="">Please select</option>
                {yes_no_options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="findtutor-location">Location*</Form.Label>
              <Form.Control
                id="findtutor-location"
                type="text"
                value={location}
                placeholder="Location"
                onChange={(event) => setLocation(event.target.value)}
                isInvalid={submitted && !location}
                maxLength={255}
              />
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="findtutor-online" className="mb-2">Online*</Form.Label>
              <Form.Select
                id="findtutor-online"
                value={online}
                onChange={handleChangeOnline}
                isInvalid={submitted && !online}
              >
                <option value="">Please select</option>
                {yes_no_options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="findtutor-date">Start Date*</Form.Label>
              <Form.Control
                id="findtutor-date"
                type="date"
                value={startDate}
                onChange={(event) => setStartDate(event.target.value)}
                min={today}
                isInvalid={submitted && !startDate}
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="findtutor-notes">Important Notes</Form.Label>
              <Form.Control
                id="findtutor-notes"
                as="textarea"
                value={importantNotes}
                placeholder="Please provide us with any special requirements"
                onChange={(event) => setImportantNotes(event.target.value)}
                maxLength={255}
              />
            </Form.Group>
          </Col>
        </Row>
      }
      <p className="ezy__form-note mt-4">
        Please note: All fields marked with a * are required to be filled out to submit the form.
      </p>
      <button
        variant=""
        type="submit"
        className="ezy__form-btn-submit w-100"
        disabled={
          !interest.trim() ||
          !String(price).trim() ||
          !academic_level.trim() ||
          !course.trim() ||
          !online.trim() ||
          !location.trim() ||
          !startDate.trim() ||
          (isSchoolCourse && !grade_level)
        }      >
        Submit
      </button>
    </Form>
  )
}

const FindTutorFormCard = ({ setShowModal }) => {
  return (
    <div className="ezy__form-form-card">
      <div className="p-0">
        <h2 className="ezy__form-heading mb-4 mb-md-5">Find Your Tutor</h2>
        <FindTutorForm
          setShowModal={setShowModal}
        />
      </div>
    </div>
  )
};



function FindTutor({ }) {
  /* Pop Up Section when form is successfully submitted */

  const nextSteps = [
    "We will post your request on our Instagram page.",
    "Interested tutors will apply through our website.",
    "We will shortlist the top 5 candidates based on your criteria.",
    "You will be notified with a link to view the candidates.",
    "You can then choose the tutor that best fits your needs."
  ];

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setShowModal(false);
    navigate(`/students/home`);
  }

  const popupInformation = {
    title: 'Successful Submit',
    body: `Thank you for using our services! We will get back to you as soon as we process your information!🌟\n You can check all your requests in the "Manage Your Posts" section. \n Next Steps:\n` +
      nextSteps.map((step, index) => `${index + 1}. ${step}`).join('\n'),
    btn_background: Config.purple_color
  };

  return (
    <section className="ezy__form light d-flex">
      <Container>
        <Row className="justify-content-between h-100">
          <Col lg={5}>
            <div
              className="ezy__form-bg-holder d-none d-lg-block h-100"
              style={{ backgroundImage: `url(${side_form})` }}
            />
          </Col>
          <Col lg={6} className="py-5">
            <Row className="align-items-center h-100">
              <Col xs={12}>
                <FindTutorFormCard
                  setShowModal={setShowModal}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <PopupModal
        show={showModal}
        onHide={handleCloseModal}
        additionalInfo={popupInformation}
      />
    </section>
  )

}


export default FindTutor;
