import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const TutorialVideo = () => {
	return (
		<section className="ezy__about6 light" id="ezy__about6">
			<Container>
				<Row className="justify-content-center text-center mb-5">
					<Col xs={12} md={8}>
						<h2 className="ezy__about6-heading fw-bold mb-4">Platform Walkthrough</h2>
						<p className="ezy__about6-sub-heading">
							Watch this video to learn how to use our platform to its fullest potential.
						</p>
					</Col>
						<div className="ratio ratio-16x9">
							<iframe
								src="https://www.youtube.com/embed/P3rKL1agufM?si=uF4qwdZPgD0Ao-vr"
								title="YouTube video"
								allowFullScreen
							></iframe>
						</div>
				</Row>
			</Container>
		</section>
	);
};

export default TutorialVideo;