import { React, useEffect } from "react";
import { useLocation } from "react-router-dom";
import '../../assets/css/landing.css';
import Testimonial19 from "./Testimonials";
import AboutUs6 from "./About";
import ContactUs10 from "./Contact";
import HowItWorks1 from "./HowItWorks";
import CardsComponent from '../../components/Cards';
import purple_background from "../../assets/images/landing-images/get-started-card-1.png";
import green_background from "../../assets/images/landing-images/get-started-card-2.png";
import home_background from '../../assets/images/landing-images/get-started-bg.png';
import TutorialVideo from "./TutorialVideo";


const cards = [
  [{
    photo: purple_background,
    label: "Match with a tutor",
    description: "Find the perfect tutor to help you achieve your academic goals.",
    navigate_to: "students/home",
    button_text: "Start Searching"
  },
  {
    photo: green_background,
    label: "Share your knowledge",
    description: "Connect with students looking for support in their studies.",
    navigate_to: "tutors/home",
    button_text: "Start Teaching"
  },
  ]
];


const Landing = () => {
  const location = useLocation();

  useEffect(() => {
    // This will be triggered on the first render and when the URL changes
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));  // get_started
      if (element) {
        // Scroll the element into view
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);  // Depend on the location, so it triggers on hash changes

  return (
    <>
      <div className="landing">
        <section className="homepage" id="home">
          <div className="content">
            <div className="text">
              <h1>Find Your Tutor Today</h1>
              <p>Set your price, choose your tutor, and ace your studies effortlessly.</p>
            </div>
            <a href="#steps">How it works?</a>
          </div>
        </section>
        <section id="steps">
          <HowItWorks1 />
        </section>
        <section id="tutorial">
          <TutorialVideo />
        </section>
      </div>

      <section className="landing-section" id="get-started">
        <CardsComponent
          title="Get Started Here!"
          description="Embark on a personalized educational journey where you have the freedom to choose your path. Whether you’re seeking expert guidance to enhance your studies or looking to share your knowledge with eager learners, we’ve got you covered."
          cards={cards}
          bg_img={home_background}
          bg_style="transparent-bg-color"
          text_style="black-color"
        />
      </section>

      <div className="landing">
        <section id="about">
          <AboutUs6 />
        </section>
        <section id="testimonials">
          <Testimonial19 />
        </section>
        <section id="contact">
          <ContactUs10 />
        </section>
      </div>
    </>

  );
};

export default Landing;
