import React from "react";
import { Routes, Route } from 'react-router-dom';

import Navbar from './components/Navbar';
import Landing from "./pages/Landing/Landing";
import TutorsHome from "./pages/Tutors/TutorsHome";
import StudentsHome from "./pages/Students/StudentsHome";
import Login from "./pages/Login/Login"
import Register from "./pages/Register/Register";
import Admin from "./pages/Admin/Admin";
import FindTutor from "./pages/Students/FindTutor";
import SelectTutor from "./pages/Students/SelectTutor";
import ApplyForPosition from "./pages/Tutors/ApplyForPosition";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import Unauthorized from "./pages/Unauthorized/Unauthorized";
import Missing from "./pages/Missing/Missing";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
// import Settings from "./pages/Settings/Settings";
import ManageApplications from "./pages/Tutors/ManageApplications";
import RedirectIfAuthenticated from "./components/RedirectIfAuthenticated";
import "./assets/fontawesome/css/all.min.css";
import { CountryProvider } from "./context/Country";
import ManageRequests from "./pages/Admin/ManageRequests";
import ManageTutorApplications from "./pages/Admin/ManageTutorApplications";
import ScrollToTop from "./components/ScrollToTop";


function App() {
  return (
    <div>
      <CountryProvider>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>

            {/* public routes */}
            <Route path='/' element={<Landing />} />
            <Route path='/login' element={<RedirectIfAuthenticated><Login /></RedirectIfAuthenticated>} />
            <Route path='/register' element={<RedirectIfAuthenticated><Register /></RedirectIfAuthenticated>} />
            <Route path='/forget/password' element={<RedirectIfAuthenticated><ForgetPassword /></RedirectIfAuthenticated>} />
            <Route path='/unauthorized' element={<Unauthorized />} />


            {/* protected routes */}
            <Route element={<RequireAuth allowedRoles={"admin"} />}>
              <Route path="/admin" element={<Admin />} />
              <Route path="/admin/requests" element={<ManageRequests />} />
              <Route path="/admin/tutor/applications" element={<ManageTutorApplications />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={"user"} />}>
              <Route path="/tutors/home" element={<TutorsHome />} />
              <Route path="/students/home" element={<StudentsHome />} />
              <Route path="/students/find/tutor" element={<FindTutor />} />
              <Route path="/students/select/tutor" element={<SelectTutor />} />
              <Route path="/tutors/apply/position" element={<ApplyForPosition />} />
              <Route path="/tutors/manage/applications" element={<ManageApplications />} />
              {/* <Route path="/settings" element={<Settings />} /> */}
            </Route>

            {/* catch all */}
            <Route path='*' element={<Missing />} />
          </Route>
        </Routes>
      </CountryProvider>
    </div>
  );
}

export default App;
