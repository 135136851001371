import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import about_us_1 from "../../assets/images/landing-images/about-us-pic-1.png";
import about_us_2 from "../../assets/images/landing-images/about-us-pic-2.png";



const stories = [
	{
		title: "Our platform",
		description:
			"Our platform connects students with experienced tutors who are ready to help with studies, projects, and exams studies. By allowing you to set your own price, we ensure that you find the perfect tutor who fits both your learning goals and financial constraints.",
		image: about_us_1,
	},
	{
		title: "The way to successful learning",
		description:
			"Whether you need a one-time session or ongoing support, we're here to make the process of finding the right tutor as easy and efficient as possible. Join us today and take the first step towards unlocking your full potential and securing a successful academic future!",
		image: about_us_2,
	},
];

const StoryItem = ({ item, index }) => {
	const { title, description, image } = item;
	return (
		<>
			<Col
				xs={12}
				md={5}
				className={index % 2 === 0 ? "order-2" : "order-2 order-md-1"}
			>
				<div
					className={`d-flex flex-column justify-content-center ezy__about6-content ${
						index % 2 === 0 ? "ps-md-5" : "pe-md-5"
					}`}
				>
					<h4 className="fw-bold mb-3">{title}</h4>
					<p className="mb-0">{description}</p>
				</div>
			</Col>
			<Col
				xs={12}
				md={5}
				className={`${
					index % 2 === 0 ? "order-1" : "order-1 order-md-2"
				} mb-4 mb-md-0`}
			>
				<div>
					<img src={image} alt={title} className="img-fluid" />
				</div>
			</Col>
		</>
	);
};

StoryItem.propTypes = {
	item: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
};

const AboutUs6 = () => {
	return (
		<section className="ezy__about6 light" id="ezy__about6">
			<Container>
				<Row className="justify-content-center text-center mb-5">
					<Col xs={12} md={8}>
						<h2 className="ezy__about6-heading fw-bold mb-4">About Us</h2>
						<p className="ezy__about6-sub-heading">
                        We believe that everyone deserves access to high-quality education tailored to their unique needs and budget.
						</p>
					</Col>
				</Row>

				{stories.map((item, i) => (
					<Row
						className="align-items-center justify-content-center mt-5"
						key={i}
					>
						<StoryItem item={item} index={i + 1} />
					</Row>
				))}
			</Container>
		</section>
	);
};

export default AboutUs6;

