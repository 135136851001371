import Table from '../../components/Table';

import Config from '../../config/Config';
import '../../assets/css/admin.css';

function ManageTutorApplications() {

  const get_tutor_applications_url = `${Config.backendURL}${Config.adminTutorApplicationsEndpoint}`;

  const table_headers = {
    common_tutor_applications_headers: ['tutor_application_id', 'post_id', 'current_status', 'additional_info'],
  }

  return (
    <div className='admin'>
      <Table
        url={get_tutor_applications_url}
        tableHeaders={table_headers.common_tutor_applications_headers}
        tableName="Tutor Applications"
        isTutorTable={true}
      />
    </div>
  )
}

export default ManageTutorApplications;
