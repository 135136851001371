import React from 'react'
import CardsComponent from '../../components/Cards';

import purple_background from "../../assets/images/purple-background.png";
import student_bg from "../../assets/images/students-home.png";


const cards = [
	[
		{
			photo: purple_background,
			label: "Manage Your Posts",
			description: "Stay updated with your active tutoring requests and track their progress.",
			navigate_to: "students/select/tutor",
			button_text: "Manage Posts"
		},
		{
			photo: purple_background,
			label: "Find Your Tutor",
			description: "Fill out a simple form to specify your academic needs and budget.",
			navigate_to: "students/find/tutor",
			button_text: "Explore Now"
		}
	]
];


function StudentsHome() {

	return (
		<CardsComponent
			title="Connect with Expert Tutors!"
            description="Find the perfect tutor for your needs by filling out a simple online form. Start your exciting learning journey today with just a few clicks."
			cards={cards}
			bg_img={student_bg}
		/>
	)
}

export default StudentsHome;
