import React, { useState } from "react";
import {
    Button,
    Card,
    Carousel,
    Col,
    Container,
    Row,
} from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import Config from '../../config/Config';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import purple_background from "../../assets/images/male_tutor.png";
import green_background from "../../assets/images/female_tutor.png";
import '../../assets/css/cards-carousel.css';
import Swal from 'sweetalert2';
import PopupModal from "../../components/PopupModal";



const Tutors = ({ tutors, post_id, setShowModal, tutorsLinks }) => {
    const axiosPrivate = useAxiosPrivate();

    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    // Start Pop up section
    const [showTutorModal, setShowTutorModal] = useState(false);
    const handleCloseModal = () => setShowTutorModal(false);
    const [selectedTutor, setSelectedTutor] = useState(null);
    const [selectedPostId, setSelectedPostId] = useState(null);

    const handleConfirmSelection = async () => {
        if (selectedTutor) {
            // confirm tutor selection and submit it to the backend
            setLoading(true);
            // change the status of the post by adding the tutor username and making the status FULFILLED
            const update_post_url = `${Config.backendURL}${Config.postsEndpoint}/${selectedPostId}`;

            const update_data = {
                "tutor_username": selectedTutor.username,
                "current_status": Config.Status.POST.FULFILLED
            };
            try {
                await axiosPrivate.put(update_post_url, update_data);
                setShowModal(true);
            } catch (error) {
                if (process.env.NODE_ENV !== 'production') {
                    console.error(error);
                }
                Swal.fire({
                    title: 'Error!',
                    text: 'Error selecting tutor, please try again later.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                });
            } finally {
                setLoading(false);
            }
        }
    };
    const popupInformation = {
        title: 'Confirm Selection',
        body: 'Are you sure you want to select this tutor?',
        id: selectedPostId,
        btn_background: Config.purple_color
    }
    // End Pop up section


    const handleSelectTutor = async (tutor, post_id) => {
        setShowTutorModal(true);
        setSelectedTutor(tutor);
        setSelectedPostId(post_id);
    };

    return (
        <>
            {loading ? (
                <div className="text-center">
                    <Spinner animation="border" variant="dark" />
                    <p>Loading...</p>
                </div>
            ) :
                <div className="ezy__header30-cards">
                    <Container>
                        <Row className="mt-5">
                            <Carousel
                                activeIndex={index}
                                onSelect={handleSelect}
                                controls={true}
                                indicators={false}
                                slide={false}
                                interval={null}
                            >
                                {
                                    tutors.map((tutorsList, i) => (
                                        <Carousel.Item key={i}>
                                            <Row className="d-flex justify-content-center align-items-center">
                                                {tutorsList.map((tutor, j) => (
                                                    <Col xs={12} md={4} key={j}>
                                                        <Card className="border-0 mt-4 mt-lg-0">
                                                            <Card.Header className="position-relative p-0 border-0">
                                                                {
                                                                    tutor.gender == 'male' ? (
                                                                        <img
                                                                            className="card-img-top"
                                                                            src={purple_background}
                                                                            alt="male tutor"
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            className="card-img-top"
                                                                            src={green_background}
                                                                            alt="female tutor"
                                                                        />
                                                                    )
                                                                }

                                                                <div className="ezy__header30-card-content">
                                                                    <h5>Name: {tutor.given_name} {tutor.family_name}</h5>
                                                                    <p>Age: {new Date().getFullYear() - new Date(tutor.birthdate).getFullYear()}</p>

                                                                    {tutorsLinks[tutor.username]?.resume && (
                                                                        <p>
                                                                            Resume:{" "}
                                                                            <a href={tutorsLinks[tutor.username]?.resume} target="_blank" rel="noopener noreferrer">
                                                                                <u>Download</u>
                                                                            </a>
                                                                        </p>

                                                                    )}
                                                                    {tutorsLinks[tutor.username]?.cover_letter && (
                                                                        <p>
                                                                            Cover Letter:{" "}
                                                                            <a href={tutorsLinks[tutor.username]?.cover_letter} target="_blank" rel="noopener noreferrer">
                                                                                <u>Cover Letter</u>
                                                                            </a>
                                                                        </p>

                                                                    )}
                                                                    {tutorsLinks[tutor.username]?.additional_file && (
                                                                        <p>
                                                                            Additional File:{" "}
                                                                            <a href={tutorsLinks[tutor.username]?.additional_file} target="_blank" rel="noopener noreferrer">
                                                                                <u>Additional File</u>
                                                                            </a>
                                                                        </p>

                                                                    )}
                                                                    <Button
                                                                        variant=""
                                                                        className="ezy__header30-btn mt-3"
                                                                        onClick={() => handleSelectTutor(tutor, post_id)}
                                                                    >
                                                                        Select Tutor
                                                                    </Button>
                                                                </div>
                                                            </Card.Header>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Carousel.Item>
                                    ))
                                }
                            </Carousel>
                        </Row>
                    </Container>
                    <PopupModal
                        show={showTutorModal}
                        onHide={handleCloseModal}
                        onConfirm={handleConfirmSelection}
                        additionalInfo={popupInformation}
                    />
                </div>
            }
        </>
    );
};

export default Tutors;
