import React from 'react'
import CardsComponent from '../../components/Cards';

import green_bg from "../../assets/images/green-background.png";
import tutor_bg from "../../assets/images/tutors-home.png";



const cards = [
	[
		{
			photo: green_bg,
			label: "My Applications",
			description: "Monitor the status of your applications and stay informed about your opportunities.",
			navigate_to: "tutors/manage/applications",
			button_text: "Check Status"
		},
		{
			photo: green_bg,
			label: "Become a Tutor",
			description: "Offer your expertise to students seeking academic guidance.",
			navigate_to: "tutors/apply/position",
			button_text: "Get Started"
		},
		// {
		// 	photo: green_bg,
		// 	label: "Manage Documents",
		// 	description: "Update and manage your personal documents to keep your profile up-to-date and ready for opportunities.",
		// 	navigate_to: "tutors_documents",
		// 	button_text: "Update Now"
		// }
	]
];


function TutorsHome() {

	return (
		<CardsComponent
			title="Your Tutoring Hub"
			description="Here you can effortlessly connect with eager students and manage your applications. Keep track of your progress and ensure your profile is always up to date."
			cards={cards}
			bg_img={tutor_bg}
		/>
	)
}

export default TutorsHome;
