import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import contact_us_img from '../../assets/images/landing-images/contact-us.png';


const contactInfoList = [
	{
		icon: "fas fa-envelope-open-text",
		label: "tutorsoflebanon@gmail.com",
		href: "mailto:tutorsoflebanon@gmail.com",
	},
	{
		icon: "fas fa-hdd",
		label: "find-your-tutor.com",
		href: "/login"
	},
];


const ContactFormCard = () => (
	<Card className="ezy__contact10-form-card">
		<Card.Body className="p-md-5">
			<h2 className="ezy__contact10-heading mb-3">Contact Us</h2>
			<p className="ezy__contact10-sub-heading mb-5">
				Reach out to us for any inquiries or feedback.
			</p>
			<p className="ezy__contact10-sub-heading mb-5">
				We value your input and are here to assist you with any questions or concerns you may have. Whether you need more information about our services, have specific inquiries, or wish to provide feedback, our team is ready to help.
			</p>
			<p className="ezy__contact10-sub-heading mb-5">
				Your satisfaction is our priority, and we are committed to responding promptly to ensure all your needs are met. Let's connect and work together to achieve your goals.

			</p>


			{/* <ContactForm /> */}
		</Card.Body>
	</Card>
);

const ContactInfo = ({ contactInfoList }) => (
	<div className="ezy__contact10-links">
		{contactInfoList.map((info, i) => (
			<Card
				className={classNames("ezy__contact10-link", { "mt-4": i })}
				key={i}
			>
				<Card.Body className="d-flex align-items-center">
					<div className="fs-3 px-2 lh-1">
						<i className={info.icon}></i>
					</div>
					<a
						className="ezy__contact10-link-content ms-4"
						href={info.href || "#!"}
					>
						{info.label}
					</a>
				</Card.Body>
			</Card>
		))}
	</div>
);

ContactInfo.propTypes = {
	contactInfoList: PropTypes.array.isRequired,
};

const ContactUs10 = () => {
	return (
		<section className="ezy__contact10 light">
			<Container>
				<Row className="justify-content-between">
					<Col lg={6} className="order-lg-2 mb-3 mb-lg-0">
						<div
							className="ezy__contact10-bg-holder h-100 d-flex align-items-center py-5"
							style={{ backgroundImage: `url(${contact_us_img})` }}
						>
							<ContactInfo contactInfoList={contactInfoList} />
						</div>
					</Col>
					<Col lg={5}>
						<ContactFormCard />
					</Col>
				</Row>
			</Container>
		</section>
	);
};


export default ContactUs10;