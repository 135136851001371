import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import '../../assets/css/image-form-side-by-side.css';
// import google_img from "../../assets/images/google.png";
import {
	Col,
	Container,
	Form,
	Row,
} from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import CodeVerification from "../../components/CodeVerification";
import UserPool from "../../components/UserPool";
import side_form from "../../assets/images/side-form-2.png";
import Swal from 'sweetalert2';
import configInstance from "../../config/Config";
import { CountryContext } from '../../context/Country';


// const SocialLoginButton = () => (
// 	<Fragment>
// 		<button className="ezy__form-btn w-100 d-flex align-items-center">
// 			<img src={google_img} alt="" className="google-img" />
// 			<span className="w-100 text-center">Continue with Google</span>
// 		</button>
// 	</Fragment>
// );

const SignUpForm = ({ setVerificationProcess, email, setEmail }) => {
	const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>?/`~]).{8,24}$/;
	const PHONE_REGEX = /^\+(?:[0-9]●?){9,18}[0-9]$/;

	const { country } = useContext(CountryContext);
	const PHONE_NUMBER_PREFIX = "+961";

	const date_minus_ten_years = new Date();
	date_minus_ten_years.setFullYear(date_minus_ten_years.getFullYear() - 10);
	const formatted_date = date_minus_ten_years.toISOString().split('T')[0];


	const [validated, setValidated] = useState(false);
	const [submitted, setSubmitted] = useState(false);

	const errRef = useRef();
	const [loading, setLoading] = useState(false);


	const [givenName, setGivenName] = useState('');
	const [lastName, setLastName] = useState('');
	const [gender, setGender] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [birthdate, setBirthdate] = useState('');
	const [instagramAccount, setInstagramAccount] = useState('');
	const [acceptContact, setAcceptContact] = useState(false);


	const [validPhoneNumber, setValidPhoneNumber] = useState(false);

	const [validEmail, setValidEmail] = useState(false);

	const [pwd, setPwd] = useState('');
	const [validPwd, setValidPwd] = useState(false);
	const [pwdFocus, setPwdFocus] = useState(false);

	const [matchPwd, setMatchPwd] = useState('');
	const [validMatch, setValidMatch] = useState(false);
	const [matchFocus, setMatchFocus] = useState(false);

	const [errMsg, setErrMsg] = useState('');


	// validate email with regex
	useEffect(() => {
		setValidEmail(EMAIL_REGEX.test(email));
	}, [email])

	// validate phone number with regex
	useEffect(() => {
		setValidPhoneNumber(PHONE_REGEX.test(`${PHONE_NUMBER_PREFIX}${phoneNumber}`));
	}, [phoneNumber])

	// validate email with regex & verify that confirm password matches current password
	useEffect(() => {
		setValidPwd(PWD_REGEX.test(pwd));
		setValidMatch(pwd === matchPwd);
	}, [pwd, matchPwd])

	// empty error message on each change of email, pwd or matchPwd
	// this ErrMsg is only returned in console, if trying to by pass HTML. 
	useEffect(() => {
		setErrMsg('');
	}, [email, pwd, matchPwd])

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrMsg('');

		const v1 = EMAIL_REGEX.test(email);
		const v2 = PWD_REGEX.test(pwd);
		const v3 = PHONE_REGEX.test(`${PHONE_NUMBER_PREFIX}${phoneNumber}`);

		if (!v1 || !v2 || !v3) {
			setErrMsg("Invalid Entry");
			return;
		}

		if (!acceptContact) {
            setErrMsg("Please check the box to agree to be contacted via email and/or Instagram.");
            return;
        }

		// Check if the Instagram account is missing
		if (!instagramAccount) {
			Swal.fire({
				title: 'Instagram Account Missing',
				text: 'If you provide your Instagram account, communication with you will be easier via Direct Messages on Instagram. Otherwise, we will use your email to contact you.',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Continue without Instagram',
				cancelButtonText: 'Add Instagram Account',
				reverseButtons: false,
				confirmButtonColor: "#6e7881",
				cancelButtonColor: configInstance.purple_color
			}).then((result) => {
				if (result.isConfirmed) {
					// Continue without Instagram account
					submitForm();
				} else {
					// Focus on Instagram input to allow user to add it
					document.getElementById("signup2-instagram-account").focus();
				}
			});
		} else {
			// If Instagram account is provided, proceed with the form submission
			submitForm();
		}
	};

	const submitForm = async () => {
		setSubmitted(true); // Mark that the form has been submitted
		setLoading(true);

		// Call Cognito API with all custom fields.
		UserPool.signUp(email, pwd, [
			{
				Name: 'gender',
				Value: gender
			},
			{
				Name: 'given_name',
				Value: givenName
			},
			{
				Name: 'family_name',
				Value: lastName
			},
			{
				Name: 'birthdate',
				Value: birthdate
			},
			{
				Name: 'phone_number',
				Value: `${PHONE_NUMBER_PREFIX}${phoneNumber}`
			},
			{
				Name: 'custom:country',
				Value: country
			},
			{
				Name: 'custom:instagram_account',
				Value: instagramAccount
			}
		],
			null,
			(err, data) => {
				if (err) {
					setErrMsg(err.message);
					errRef.current.focus();  // Focus on the error message
					// alert(err);
				}
				else {
					// verification process is displayed instead of register form.
					// a code sent to the email of the user will be required to be entered.
					setVerificationProcess(true);
				}
				setLoading(false);
			})
	}

	return (
		<Form noValidate validated={validated} onSubmit={handleSubmit}>
			<p ref={errRef} className={errMsg ? "ezy__form-errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
			{loading ? (
				<div className="text-center">
					<Spinner animation="border" variant="dark" />
					<p>Loading...</p>
				</div>
			) :
				<>
					<Row>
						<Col lg={6}>
							<Form.Group className="mb-4">
								<Form.Label htmlFor="signup2-first-name">First Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="Your First Name"
									id="signup2-first-name"
									onChange={(e) => setGivenName(e.target.value)}
									value={givenName}
									isInvalid={submitted && !givenName}
								/>
							</Form.Group>
						</Col>
						<Col lg={6}>
							<Form.Group className="mb-4">
								<Form.Label htmlFor="signup2-last-name">Last Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="Your Last Name"
									id="signup2-last-name"
									onChange={(e) => setLastName(e.target.value)}
									value={lastName}
									isInvalid={submitted && !lastName}
								/>
							</Form.Group>
						</Col>
						<Col lg={6}>
							<Form.Group className="mb-4">
								<Form.Label htmlFor="birthdate" className="mb-2">
									Birthdate
								</Form.Label>
								<Form.Control
									type="date"
									id="birthdate"
									onChange={(e) => setBirthdate(e.target.value)}
									value={birthdate}
									max={formatted_date}
									isInvalid={submitted && !birthdate}
								/>
							</Form.Group>
						</Col>
						<Col lg={6}>
							<Form.Group className="mb-4">
								<Form.Label htmlFor="signup2-gender" className="mb-2">
									Gender
								</Form.Label>
								<Form.Select
									id="signup2-gender"
									onChange={(e) => setGender(e.target.value)}
									value={gender}
									isInvalid={submitted && !gender}
								>
									<option value="">--Please Select</option>
									<option value="female">Female</option>
									<option value="male">Male</option>
								</Form.Select>
							</Form.Group>
						</Col>
						<Col lg={6}>
							<Form.Group className="mb-4">
								<Form.Label htmlFor="signup2-email" className="mb-2">
									Email
								</Form.Label>
								<Form.Control
									type="email"
									id="signup2-email"
									placeholder="Your Email Address"
									onChange={(e) => setEmail(e.target.value)}
									value={email}
								/>
								<p className={email && !validEmail ? "ezy__form-instructions" : "ezy__form-offscreen"}>Please provide a valid email address.</p>
							</Form.Group>
						</Col>
						<Col lg={6}>
							<Form.Group className="mb-4">
								<Form.Label htmlFor="signup2-phone-number">Phone Number</Form.Label>
								<div className="d-flex align-items-center">
									<div>
										{PHONE_NUMBER_PREFIX}
									</div>
									<Form.Control
										type="tel"
										placeholder="Your Phone Number"
										id="signup2-phone-number"
										onChange={(e) => setPhoneNumber(e.target.value)}
										value={phoneNumber}
										isInvalid={submitted && !phoneNumber}
									/>
								</div>
								<p className={phoneNumber.trim() && !validPhoneNumber ? "ezy__form-instructions" : "ezy__form-offscreen"}>Please provide a valid phone number.</p>
							</Form.Group>
						</Col>
						<Col lg={6}>
							<Form.Group className="mb-4">
								<Form.Label htmlFor="signup2-password" className="mb-2">
									Password
								</Form.Label>
								<Form.Control
									type="password"
									id="signup2-password"
									placeholder="Enter Password"
									onChange={(e) => setPwd(e.target.value)}
									value={pwd}
									isInvalid={submitted && !pwd}
									onFocus={() => setPwdFocus(true)}
									onBlur={() => setPwdFocus(false)}
								/>
								<p className={pwd.trim() && !validPwd ? "ezy__form-instructions" : "ezy__form-offscreen"}> 8 to 24 characters. Must include uppercase and lowercase letters, a number and symbol.</p>
							</Form.Group>
						</Col>
						<Col lg={6}>
							<Form.Group className="mb-4">
								<Form.Label htmlFor="signup2-confirm-password" className="mb-2">
									Confirm Password
								</Form.Label>
								<Form.Control
									type="password"
									id="signup2-confirm-password"
									placeholder="Confirm Password"
									onChange={(e) => setMatchPwd(e.target.value)}
									value={matchPwd}
									isInvalid={submitted && !matchPwd}
									onFocus={() => setMatchFocus(true)}
									onBlur={() => setMatchFocus(false)}
								/>
								<p className={!validMatch ? "ezy__form-instructions" : "ezy__form-offscreen"}>Password does not match.</p>
							</Form.Group>
						</Col>
						<Col lg={6}>
							<Form.Group className="mb-4">
								<Form.Label htmlFor="signup2-country" className="mb-2">
									Country
								</Form.Label>
								<Form.Control
									type="text"
									placeholder="Lebanon"
									id="signup2-country"
									value={country}
									readOnly
								/>
							</Form.Group>
						</Col>
						<Col lg={6}>
							<Form.Group className="mb-4">
								<Form.Label htmlFor="signup2-instagram-account" className="mb-2">
									Instagram Account
								</Form.Label>
								<Form.Control
									type="text"
									placeholder="Instagram Account"
									id="signup2-instagram-account"
									onChange={(e) => setInstagramAccount(e.target.value)}
									value={instagramAccount}
								/>
							</Form.Group>
						</Col>
						<Col lg={6}>
                            <Form.Group className="mb-4">
                                <Form.Check
                                    type="checkbox"
                                    id="contact-consent"
                                    label="I agree to be contacted via email and/or Instagram."
                                    onChange={(e) => setAcceptContact(e.target.checked)}
                                    checked={acceptContact}
                                    isInvalid={submitted && !acceptContact}
                                />
                                <p className={submitted && !acceptContact ? "ezy__form-instructions" : "ezy__form-offscreen"}>
                                    You must accept the terms to proceed.
                                </p>
                            </Form.Group>
                        </Col>
					</Row>
					<button
						variant=""
						type="submit"
						className="ezy__form-btn-submit w-100"
						disabled={!validEmail || !validPwd || !validMatch || !validPhoneNumber || !givenName.trim() || !lastName.trim() || !birthdate.trim() || !gender.trim() || !acceptContact ? true : false}
					>
						Sign Up
					</button>
				</>
			}
		</Form>
	);
};

const SignUpFormCard = ({ setVerificationProcess, email, setEmail }) => {
	let navigate = useNavigate();

	const handleSignIn = () => {
		navigate('/login');
	};
	return (
		<div className="ezy__form-form-card">
			<div className="p-0">
				<h2 className="ezy__form-heading mb-4 mb-md-5">Sign Up</h2>

				<SignUpForm
					setVerificationProcess={setVerificationProcess}
					email={email}
					setEmail={setEmail}
				/>

				{/* <div className="position-relative ezy__form-or-separator">
					<hr className="my-4 my-md-5" />
					<span className="px-2">Or</span>
				</div> */}

				{/* <SocialLoginButton /> */}

				<div className="text-center mt-4 mt-md-5">
					<p className="mb-0 opacity-50 lh-1">Already have an account?</p>
					<button variant="link" className="ezy__form-btn-sign-in py-0 text-decoration-none" onClick={handleSignIn}>
						Sign In
					</button>
				</div>
			</div>
		</div>
	)
};

const Register = () => {
	const [verificationProcess, setVerificationProcess] = useState(false);
	const [email, setEmail] = useState('');

	//called by the CodeVerification component (child component) when the verification process succeeds
	const handleVerificationProcess = (value) => {
		setVerificationProcess(value);
	}

	return (
		<>
			{
				verificationProcess ? (
					<CodeVerification email={email} page={"Register"} />
				) : (
					<section className="ezy__form light d-flex">
						<Container>
							<Row className="justify-content-between h-100">
								<Col lg={5}>
									<div
										className="ezy__form-bg-holder d-none d-lg-block h-100"
										style={{ backgroundImage: `url(${side_form})` }}
									/>
								</Col>
								<Col lg={6} className="py-5">
									<Row className="align-items-center h-100">
										<Col xs={12}>
											<SignUpFormCard
												setVerificationProcess={setVerificationProcess}
												email={email}
												setEmail={setEmail} />
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</section>
				)
			}
		</>
	);
};


export default Register;
