import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import man_woman_in_book from "../../assets/images/landing-images/man-woman-in-book.png";


const contents = [
	{
		title: "Create an account",
		text: "Simply register using your email address and provide us with your instagram username.",
	},
	{
		title: "Upload your request",
		text: "By filling a simple form, your request will be processed in a couple of days and posted to our instagram page.",
	},
	{
		title: "Tutors Filtering",
		text: "Interested tutors will send their documents through our website and our team will filter the best applicants for you.",
	},
	{
		title: "Choose your tutor",
		text: "You can select the perfect tutor through our website. After that our team will share the tutor's details with you.",
	},
];

const ContentItem = ({ item, index }) => (
	<div className="p-xl-3 mt-4">
		<div className="ezy__howitworks1-number mb-3">{index + 1}</div>
		<h4 className="ezy__howitworks1-title fs-4 mb-3">{item.title}</h4>
		<p className="ezy__howitworks1-content mb-0">{item.text}</p>
	</div>
);

ContentItem.propTypes = {
	item: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
};

const HowItWorks1 = () => {
	return (
		<section className="ezy__howitworks1 light">
			<Container>
				<Row className="justify-content-between">
					{/* image  */}
					<Col xs={12} lg={5}>
						<img
							src={man_woman_in_book}
							alt=""
							className="img-fluid mb-5 mb-lg-0"
						/>
					</Col>
					<Col xs={12} lg={6}>
						<Row>
							<Col xs={12}>
								<h1 className="ezy__howitworks1-heading mb-4">
									How it works!
								</h1>
							</Col>
							{contents.map((item, i) => (
								<Col xs={12} md={6} key={i}>
									<ContentItem index={i} item={item} />
								</Col>
							))}
						</Row>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default HowItWorks1;